<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="period"
								title="기간"
								date-picker
								type="month"
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<CommonDataTableGridType02
				v-model="model"
				:headers="filterHeader"
				:desserts="desserts"
				:height="common_tableHeight(450)"
				readonly
				:loading="loading"
			/>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminEstimateConsult',
	mixins: [common, admin],
	data: () => ({
		model: [],
		desserts: [],
		period: null,
		params: {},
		loading: false,
	}),
	computed: {
		filterHeader() {
			const front = [
				{ text: 'KPI', value: 'kpi', width: '*', sortable: false },
				{ text: '측정주기', value: 'measure_cycle', width: '*', sortable: false },
			]
			const middle = []
			const rear = [
				{ text: '누계', value: 'sum', width: '*', sortable: false },
				{ text: '평균', value: 'avg', width: '*', sortable: false },
			]
			const data = this.desserts[0]
			if (!data) return [...front, ...rear]

			const dateKeys = Object.keys(data)
				.filter(key => key.startsWith('date_'))
				.sort()

			dateKeys.forEach(item => {
				const obj = { text: item.slice(5), value: item, width: '*', sortable: false }
				middle.push(obj)
			})

			console.log('filteredData', dateKeys)

			return [...front, ...middle, ...rear]
		},
	},
	created() {
		const year = this.common_get_this_year()
		this.period = [year.startDate, year.endDate]
		this.params = { startDate: year.startDate, endDate: year.endDate }
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_HOME_SUMMARY']),
		async getList() {
			this.loading = true
			console.log('this.params', this.params)
			await this.ADMIN_ACT_STATISTICS_HOME_SUMMARY(this.params).then(res => {
				this.desserts = res
				this.loading = false
			})
			console.log('this.desserts', this.desserts)
		},
		searchItems() {
			const filterDate = this.common_inputDateFilter(this.period)

			this.params.startDate = filterDate.startDate
			this.params.endDate = filterDate.endDate
			console.log('search', this.params, this.period)

			this.getList()
		},
		makeExcelFile() {
			this.common_create_excel(this.common_excel_data_convert(this.desserts, this.filterHeader), '통계 홈')
		},
	},
}
</script>

<style lang="scss" scoped></style>
