import { render, staticRenderFns } from "./home.vue?vue&type=template&id=18325a69&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18325a69",
  null
  
)

export default component.exports