<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>
						<v-col
							:key="monthly"
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								:type="monthly === 'true' ? 'month' : 'date'"
								title="기간"
								date-picker
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-row>
				<v-col>
					<v-col class="pl-0">
						<h2>메인배너 이용</h2>
					</v-col>
					<CommonDataTableGridType02
						v-model="model"
						:headers="bannerHeaders"
						:line-headers="true"
						:desserts="banners"
						:height="common_tableHeight(450)"
						:loading="loading"
						readonly
					/>
				</v-col>
				<v-col>
					<v-col class="pl-0">
						<h2>태그 이용</h2>
					</v-col>
					<CommonDataTableGridType02
						v-model="model"
						:headers="tagHeaders"
						:line-headers="true"
						:desserts="tags"
						:height="common_tableHeight(450)"
						:loading="loading"
						readonly
					/>
				</v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsServiceUseMainContent',
	mixins: [common, admin],
	data: () => ({
		model: [],
		monthly: 'true',
		period: null,
		params: {},
		loading: false,
		banners: [],
		tags: [],
		bannerHeaders: [
			{ text: '배너명', value: 'value', width: '*', align: 'center', sortable: false },
			{ text: '클릭수', value: 'cnt', width: '150px', align: 'center', sortable: false },
			{ text: '이용률', value: 'cnt_ratio', width: '150px', align: 'center', sortable: false },
		],
		tagHeaders: [
			{ text: '태그명', value: 'value', width: '*', align: 'center', sortable: false },
			{ text: '클릭수', value: 'cnt', width: '150px', align: 'center', sortable: false },
			{ text: '이용률', value: 'cnt_ratio', width: '150px', align: 'center', sortable: false },
		],
		bannerExcelHeaders: [
			{ text: '배너명', value: 'value' },
			{ text: '클릭수', value: 'cnt' },
			{ text: '이용률', value: 'cnt_ratio' },
		],
		tagExcelHeaders: [
			{ text: '태그명', value: 'value' },
			{ text: '클릭수', value: 'cnt' },
			{ text: '이용률', value: 'cnt_ratio' },
		],
	}),
	computed: {},
	watch: {
		monthly() {
			this.initPeriod()
		},
	},
	created() {
		const year = this.common_get_this_year()
		this.period = [year.startDate, year.endDate]
		this.params = { startDate: year.startDate, endDate: year.endDate }
		this.getList('banner')
		this.getList('tag')
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_MAIN_CONTENT']),
		async getList(method) {
			this.loading = true
			this.params.monthly = this.monthly
			this.params.method = method
			console.log('this.params', this.params)
			await this.ADMIN_ACT_STATISTICS_MAIN_CONTENT(this.params).then(res => {
				if (method === 'banner') this.banners = res
				if (method === 'tag') this.tags = res

				let data = method === 'banner' ? this.banners : this.tags

				// 합계 맨 위로
				const totalDataIndex = data.findIndex(item => item.value === '합계')
				if (totalDataIndex !== -1) {
					const totalData = data[totalDataIndex]
					data.splice(totalDataIndex, 1)
					data.unshift(totalData)
				}
				this.loading = false
				console.log('ADMIN_ACT_STATISTICS_MAIN_CONTENT', this.banners, this.tags)
			})
		},
		searchItems() {
			let filterDate = {}
			if (this.period) filterDate = this.common_inputDateFilter(this.period)
			else {
				filterDate = this.monthly === 'true' ? this.common_get_this_year() : this.common_get_this_month()
			}

			this.period = [filterDate.startDate, filterDate.endDate]
			this.params.startDate = filterDate.startDate
			this.params.endDate = filterDate.endDate
			console.log('search', this.params, this.period)

			this.getList('banner')
			this.getList('tag')
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			this.common_create_excel(
				this.common_excel_data_convert(this.banners, this.bannerExcelHeaders),
				'메인콘텐츠 메인배너 통계',
			)
			this.common_create_excel(this.common_excel_data_convert(this.tags, this.tagExcelHeaders), '메인콘텐츠 태그 통계')
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.table-search) {
	display: none;
}
:deep(.v-data-table) {
	tr:first-child:not(.v-data-table__empty-wrapper) {
		background: #e4efdc;
	}
}
</style>
