<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>
						<v-col
							:key="monthly"
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								:type="monthly === 'true' ? 'month' : 'date'"
								title="기간"
								date-picker
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-col class="pl-0">
				<h2>맞춤 견적</h2>
			</v-col>
			<CommonDataTableGridType04
				v-model="model"
				:binding-headers="estBindingHeaders"
				:headers="estHeaders"
				:line-headers="true"
				:desserts="estData"
				:height="common_tableHeight(450)"
				:loading="loading"
			/>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-col class="pl-0">
				<h2>전화 상담 & 견적내기</h2>
			</v-col>
			<CommonDataTableGridType04
				v-model="model"
				:binding-headers="callBindingHeaders"
				:headers="callHeaders"
				:line-headers="true"
				:desserts="callData"
				:height="common_tableHeight(450)"
				:loading="loading"
			/>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsServiceUseMenu',
	mixins: [common, admin],
	data: () => ({
		model: [],
		monthly: 'true',
		period: null,
		params: {},
		loading: false,
		estData: [],
		callData: [],
		estBindingHeaders: [
			{ value: 'get_date', align: 'center' },
			{ value: 'estCount', align: 'center' },
			{ value: 'prodDetailCount', align: 'center' },
			{ value: 'housewarmin3dCount', align: 'center' },
			{ value: 'housewarmingSpeciaCount', align: 'center' },
			{ value: 'priceCount', align: 'center' },
			{ value: 'searchCount', align: 'center' },
			{ value: 'maruRecommendCount', align: 'center' },
			{ value: 'interiorCount', align: 'center' },
			{ value: 'totalCount', align: 'center' },
			{ value: 'estConsult_ratio', align: 'center' },
		],
		callBindingHeaders: [
			{ value: 'get_date', align: 'center' },
			{ value: 'safetynumberData_count', align: 'center' },
			{ value: 'showroomData_count', align: 'center' },
			{ value: 'consult_total', align: 'center' },
			{ value: 'saleConsult_ratio', align: 'center' },
			{ value: 'fastestData_count', align: 'center' },
			{ value: 'estimateData_count', align: 'center' },
			{ value: 'estimate_total', align: 'center' },
			{ value: 'estimate_ratio', align: 'center' },
		],
		estHeaders: [
			{
				row: [
					{ text: '구분', rowspan: 2, width: '120px', align: 'center' },
					{ text: '맞춤견적 상담신청', colspan: 9, width: '*', align: 'center' },
					{ text: '상담신청 이용률', rowspan: 2, width: '*', align: 'center' },
				],
			},
			{
				row: [
					{ text: '맞춤 견적', width: '*', align: 'center' },
					{ text: '제품 상세', width: '*', align: 'center' },
					{ text: '3D집들이', width: '*', align: 'center' },
					{ text: '전문가집들이', width: '*', align: 'center' },
					{ text: '견적내기', width: '*', align: 'center' },
					{ text: '검색서비스', width: '*', align: 'center' },
					{ text: '마루 추천', width: '*', align: 'center' },
					{ text: '인테리어점', width: '*', align: 'center' },
					{ text: '소계', width: '*', align: 'center' },
				],
			},
		],
		callHeaders: [
			{
				row: [
					{ text: '구분', rowspan: 2, width: '120px', align: 'center' },
					{ text: '전화 상담', colspan: 3, width: '*', align: 'center' },
					{ text: '전화 상담 이용률', rowspan: 2, width: '*', align: 'center' },
					{ text: '견적 내기 서비스', colspan: 3, width: '*', align: 'center' },
					{ text: '견적내기 서비스 이용률', rowspan: 2, width: '*', align: 'center' },
				],
			},
			{
				row: [
					{ text: '전화상담(인테리어)', width: '*', align: 'center' },
					{ text: '전화상담(쇼룸)', width: '*', align: 'center' },
					{ text: '소계', width: '*', align: 'center' },
					{ text: '빠른견적', width: '*', align: 'center' },
					{ text: '견적내기', width: '*', align: 'center' },
					{ text: '소계', width: '*', align: 'center' },
				],
			},
		],
		estExcelHeaders: [
			{ text: '구분', value: 'get_date' },
			{ text: '맞춤 견적', value: 'estCount' },
			{ text: '제품 상세', value: 'prodDetailCount' },
			{ text: '3D집들이', value: 'housewarmin3dCount' },
			{ text: '전문가집들이', value: 'housewarmingSpeciaCount' },
			{ text: '견적내기', value: 'priceCount' },
			{ text: '검색서비스', value: 'searchCount' },
			{ text: '마루 추천', value: 'maruRecommendCount' },
			{ text: '인테리어점', value: 'interiorCount' },
			{ text: '소계 ', value: 'totalCount' },
			{ text: '상담신청 이용률', value: 'estConsult_ratio' },
		],
		callExcelHeaders: [
			{ text: '구분', value: 'get_date' },
			{ text: '전화 상담 전화상담(인테리어)', value: 'safetynumberData_count' },
			{ text: '전화 상담 전화상담(쇼룸)', value: 'showroomData_count' },
			{ text: '전화 상담 소계', value: 'consult_total' },
			{ text: '전화 상담 이용률', value: 'saleConsult_ratio' },
			{ text: '견적 내기 서비스 빠른견적', value: 'fastestData_count' },
			{ text: '견적 내기 서비스 견적내기', value: 'estimateData_count' },
			{ text: '견적 내기 서비스 소계', value: 'estimate_total' },
			{ text: '견적 내기 서비스 이용률', value: 'estimate_ratio' },
		],
	}),
	computed: {},
	watch: {
		monthly() {
			this.initPeriod()
		},
	},
	created() {
		const year = this.common_get_this_year()
		this.period = [year.startDate, year.endDate]
		this.params = { startDate: year.startDate, endDate: year.endDate }
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_CONSULT']),
		async getList() {
			this.loading = true
			this.params.monthly = this.monthly
			console.log('this.params', this.params)
			await this.ADMIN_ACT_STATISTICS_CONSULT(this.params).then(res => {
				console.log('res', res)
				this.estData = res.estConsult
				this.callData = res.callConsult
				this.loading = false
			})
		},
		searchItems() {
			let filterDate = {}
			if (this.period) filterDate = this.common_inputDateFilter(this.period)
			else {
				filterDate = this.monthly === 'true' ? this.common_get_this_year() : this.common_get_this_month()
			}

			this.period = [filterDate.startDate, filterDate.endDate]
			this.params.startDate = filterDate.startDate
			this.params.endDate = filterDate.endDate
			console.log('search', this.params, this.period)

			this.getList()
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			this.common_create_excel(this.common_excel_data_convert(this.estData, this.estExcelHeaders), '맞춤 견적 통계')
			this.common_create_excel(
				this.common_excel_data_convert(this.callData, this.callExcelHeaders),
				'전화 상담 & 견적내기 통계',
			)
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.table-search) {
	display: none;
}
:deep(.v-data-table) {
	tr:first-child:not(.v-data-table__empty-wrapper) {
		background: #e4efdc;
	}
}
</style>
