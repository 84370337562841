<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>

						<!-- 
							월/일 유형이 바뀔때마다 기존의 period값이 남아있어 date타입이 맞지 않아 오류발생.
							:key값을 추가해 타입이 바뀔 때 마다 새로 렌더링 시킴
						 -->
						<v-col
							:key="monthly"
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								title="기간"
								date-picker
								:type="monthly === 'true' ? 'month' : 'date'"
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="getList"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-row>
				<v-col
					cols="12"
					md="3"
				>
					<v-col class="pl-0">
						<h2>키워드 검색 이용률</h2>
					</v-col>
					<CommonDataTableGridType02
						v-model="modelTotal"
						:headers="totalHeaders"
						:desserts="desserts.totalCount || []"
						:height="common_tableHeight(500)"
						:loading="loading"
						readonly
					/>
				</v-col>
				<v-col
					cols="12"
					md="9"
				>
					<v-col class="pl-0">
						<h2>검색 순위</h2>
					</v-col>
					<v-row>
						<v-col
							cols="12"
							md="6"
						>
							<CommonDataTableGridType03
								v-model="modelProd"
								:headers="prodHeaders"
								:desserts="desserts.prodList || []"
								:height="common_tableHeight(500)"
								:loading="loading"
								readonly
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
						>
							<CommonDataTableGridType03
								v-model="modelKeyword"
								:headers="keywordHeaders"
								:desserts="desserts.keywordList || []"
								:height="common_tableHeight(500)"
								:loading="loading"
								readonly
							/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsServiceUseKeyword',
	mixins: [common, admin],
	data: () => ({
		modelTotal: [],
		modelProd: [],
		modelKeyword: [],
		totalHeaders: [
			{ text: '날짜/구분', value: 'get_date', width: '150px', sortable: false },
			{ text: '검색 이용 수', value: 'search_count', width: '150px', sortable: false },
			// { text: '방문자 검색 이용률', value: 'search_count', width: '150px', sortable: false },
		],
		prodHeaders: [
			{ text: '순위', value: 'ranking', width: '150px', sortable: false },
			{ text: '제품명', value: 'keyword', width: '150px', sortable: false },
			{ text: '검색 횟수', value: 'cnt', width: '150px', sortable: false },
		],
		keywordHeaders: [
			{ text: '순위', value: 'ranking', width: '150px', sortable: false },
			{ text: '키워드', value: 'keyword', width: '150px', sortable: false },
			{ text: '검색 횟수', value: 'cnt', width: '150px', sortable: false },
		],
		excelHeaders: [
			{ text: '날짜/구분', value: 'get_date' },
			{ text: '검색 이용 수', value: 'search_count' },
			{ text: 'Product 순위', value: 'prod_ranking' },
			{ text: 'Product 제품명', value: 'prod_keyword' },
			{ text: 'Product 검색 횟수', value: 'prod_cnt' },
			{ text: 'keyword 순위', value: 'keyword_ranking' },
			{ text: 'keyword 키워드', value: 'keyword_keyword' },
			{ text: 'keyword 검색 횟수', value: 'keyword_cnt' },
		],
		monthly: 'true',
		period: null,
		desserts: {},
		loading: false,
	}),
	computed: {},
	watch: {
		monthly() {
			this.initPeriod()
		},
		period(val) {
			if (val?.length === 2) {
				const start = new Date(val[0])
				const end = new Date(val[1])
				let diffPeriod = 0

				if (this.monthly === 'true')
					diffPeriod = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1
				else diffPeriod = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1

				if ((this.monthly === 'true' && diffPeriod > 12) || (this.monthly === 'false' && diffPeriod > 31)) {
					this.initPeriod()
					this.$toastr.error('월별: 1년 이내, 일별: 한 달 이내', { timeOut: 1500 })
				}
			}
		},
	},
	mounted() {
		let date = this.common_get_period_from_now('month', 3)
		this.period = [date.startDate.replace(/^(\d{4})(\d{2})$/, '$1-$2'), date.endDate.replace(/^(\d{4})(\d{2})$/, '$1-$2')]
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_SEARCH_KEYWORD']),
		async getList() {
			const date =
				this.monthly === 'true' ? this.common_get_period_from_now('month', 3) : this.common_get_period_from_now('day', 30)
			const filterDate = this.period ? this.common_inputDateFilter(this.period) : null
			const params = {
				monthly: this.monthly,
				startDate: filterDate ? filterDate.startDate : date.startDate,
				endDate: filterDate ? filterDate.endDate : date.endDate,
			}
			console.log('params', params)

			this.loading = true
			try {
				await this.ADMIN_ACT_STATISTICS_SEARCH_KEYWORD(params).then(res => {
					console.log('res', res)
					this.desserts = res
				})
			} catch (e) {
				console.log('ADMIN_ACT_STATISTICS_SEARCH_KEYWORD', e)
			}
			this.loading = false
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			const total = this.desserts.totalCount
			const prod = this.desserts.prodList.map(item => ({
				prod_ranking: item.ranking,
				prod_keyword: item.keyword,
				prod_cnt: item.cnt,
			}))
			const keyword = this.desserts.keywordList.map(item => ({
				keyword_ranking: item.ranking,
				keyword_keyword: item.keyword,
				keyword_cnt: item.cnt,
			}))

			// total, prod, keyword 배열 하나로 합치기.
			// 단, 배열 길이가 서로 달라 가장 긴 배열 기준으로 빈 데이터를 채워 넣어 줌
			const maxLength = Math.max(total.length, prod.length, keyword.length)
			const padArray = (arr, length) => arr.concat(Array.from({ length: length - arr.length }, () => ({})))

			const paddedArray1 = padArray(total, maxLength)
			const paddedArray2 = padArray(prod, maxLength)
			const paddedArray3 = padArray(keyword, maxLength)

			const result = paddedArray1.map((_, index) => ({
				...paddedArray1[index],
				...paddedArray2[index],
				...paddedArray3[index],
			}))
			console.log('result', result)
			this.common_create_excel(this.common_excel_data_convert(result, this.excelHeaders), '키워드 검색 이용률')
		},
	},
}
</script>
