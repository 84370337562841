<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>
						<v-col
							:key="monthly"
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								:type="monthly === 'true' ? 'month' : 'date'"
								title="기간"
								date-picker
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-row justify="center">
			<v-col
				cols="12"
				md="10"
				align="middle"
				class="mt-8 pt-0"
				><CommonChartsLineBarComboChart
					:bar-chart-data="filterBarChartData"
					:line-chart-data="filterLineChartData"
					height="305"
				/>
			</v-col>
		</v-row>
		<v-col class="pa-4 px-md-4 pt-0">
			<CommonDataTableGridType02
				v-model="model"
				:headers="headers"
				:line-headers="true"
				:desserts="desserts"
				:height="common_tableHeight(450)"
				:loading="loading"
				readonly
			/>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsVsitVisitor',
	mixins: [common, admin],
	data: () => ({
		model: [],
		monthly: 'true',
		period: null,
		params: {},
		loading: false,
		desserts: [],
		headers: [
			{ text: '구분', value: 'get_date', width: '150px', sortable: false },
			{ text: '방문자수', value: 'newActive_users', width: '150px', sortable: false },
			{ text: '페이지뷰수', value: 'page_view', width: '150px', sortable: false },
			{ text: '방문자활동수', value: 'active_count', width: '150px', sortable: false },
		],
		excelHeaders: [
			{ text: '구분', value: 'get_date' },
			{ text: '방문자수', value: 'newActive_users' },
			{ text: '페이지뷰수', value: 'page_view' },
			{ text: '방문자활동수', value: 'active_count' },
		],
	}),
	computed: {
		filterBarChartData() {
			if (!this.desserts) return []
			let result = [
				{ name: '방문자수', data: [] },
				{ name: '페이지뷰수', data: [] },
			]
			let chartData = this.desserts.filter(x => x.get_date !== '총계').sort((a, b) => (a.get_date > b.get_date ? 1 : -1))
			result.forEach((x, i) => {
				chartData.forEach(y => {
					x.data.push({ label: y.get_date, value: i === 0 ? y.newActive_users : y.page_view })
				})
			})
			console.log('filterBarChartData', result)
			return result
		},
		filterLineChartData() {
			if (!this.desserts) return []
			let result = [{ name: '방문자활동수', data: [] }]
			let chartData = this.desserts.filter(x => x.get_date !== '총계').sort((a, b) => (a.get_date > b.get_date ? 1 : -1))
			result.forEach((x, i) => {
				chartData.forEach(y => {
					x.data.push({ label: y.get_date, value: y.active_count })
				})
			})
			console.log('filterLineChartData', result)
			return result
		},
	},
	watch: {
		monthly() {
			this.initPeriod()
		},
	},
	created() {
		const year = this.common_get_this_year()
		this.period = [year.startDate, year.endDate]
		this.params = { startDate: year.startDate, endDate: year.endDate }
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_VISITOR']),
		async getList() {
			this.loading = true
			this.params.monthly = this.monthly
			console.log('this.params', this.params)
			await this.ADMIN_ACT_STATISTICS_VISITOR(this.params).then(res => {
				this.desserts = res
				this.loading = false
			})
			console.log('this.desserts', this.desserts)
		},
		searchItems() {
			let filterDate = {}
			if (this.period) filterDate = this.common_inputDateFilter(this.period)
			else {
				filterDate = this.monthly === 'true' ? this.common_get_this_year() : this.common_get_this_month()
			}

			this.period = [filterDate.startDate, filterDate.endDate]
			this.params.startDate = filterDate.startDate
			this.params.endDate = filterDate.endDate
			console.log('search', this.params, this.period)

			this.getList()
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			this.common_create_excel(this.common_excel_data_convert(this.desserts, this.excelHeaders), '방문자 통계')
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.table-search) {
	display: none;
}
:deep(.v-data-table) {
	tr:first-child:not(.v-data-table__empty-wrapper) {
		background: #e4efdc;
	}
}
</style>
