<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>

						<!-- 
							월/일 유형이 바뀔때마다 기존의 period값이 남아있어 date타입이 맞지 않아 오류발생.
							:key값을 추가해 타입이 바뀔 때 마다 새로 렌더링 시킴
						 -->
						<v-col
							:key="monthly"
							cols="12"
							md="4"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								title="기간"
								date-picker
								:type="monthly === 'true' ? 'month' : 'date'"
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="getList"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-row>
				<v-col
					cols="12"
					md="4"
				>
					<v-col class="pl-0">
						<h2>사진 검색 이용률</h2>
					</v-col>
					<v-row
						class="ma-0 mb-3"
						align="center"
					>
						<v-col
							cols="12"
							sm="8"
							align="start"
							class="pa-0"
						>
							<CommonTabsTab01
								v-model="tab"
								:tab-items="tabItems"
								class-name="margin_0"
							/>
						</v-col>
						<v-col
							cols="12"
							sm="4"
							align="end"
							class="pa-0"
						>
						</v-col>
					</v-row>
					<CommonDataTableGridType02
						v-if="tab === 0"
						v-model="modelTotal"
						:headers="totalHeaders"
						:desserts="desserts.totalCount || []"
						:height="common_tableHeight(500)"
						:loading="loading"
						readonly
					/>
					<CommonDataTableGridType03
						v-if="tab === 1"
						v-model="modelLogProd"
						:headers="logProdHeaders"
						:desserts="desserts.searchLogProdList || []"
						:numbers-per-page="10"
						:height="common_tableHeight(500)"
						:loading="loading"
						readonly
					/>
				</v-col>
				<v-col
					cols="12"
					md="8"
				>
					<v-col class="pl-0">
						<h2>로그</h2>
					</v-col>
					<v-row>
						<v-col cols="12">
							<CommonDataTableGridType04
								v-model="modelLog"
								class="mt-15"
								:binding-headers="bindingLogHeaders"
								:headers="logHeaders"
								:desserts="desserts.searchLogList || []"
								:numbers-per-page="10"
								:height="common_tableHeight(500)"
								:loading="loading"
								readonly
							/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsServiceUsePicture',
	mixins: [common, admin],
	data: () => ({
		tab: 0,
		tabItems: ['검색 이용률', '추천 순위'],
		modelTotal: [],
		modelLogProd: [],
		modelLog: [],
		totalHeaders: [
			{ text: '날짜/구분', value: 'get_date', width: '100px', sortable: false },
			{ text: '검색 이용 수', value: 'search_count', width: '100px', sortable: false },
			// { text: '방문자 검색 이용률', value: 'search_count', width: '150px', sortable: false },
		],
		logProdHeaders: [
			{ text: '순위', value: 'ranking', width: '50px', sortable: false },
			{ text: '제품명', value: 'name', width: '200px', sortable: false },
			{ text: 'detail', value: 'recomm_prod', width: '50px', sortable: false },
			{ text: '추천수', value: 'cnt', width: '50px', sortable: false },
		],
		bindingLogHeaders: [
			{ value: 'regi_date', align: 'center' },
			{ value: 'image', align: 'center' },
			{ value: 'picture_color1', align: 'center' },
			{ value: 'picture_color2', align: 'center' },
			{ value: 'picture_color3', align: 'center' },
			{ value: 'picture_color4', align: 'center' },
			{ value: 'recomm_prod1', align: 'center' },
			{ value: 'recomm_prod2', align: 'center' },
			{ value: 'recomm_prod3', align: 'center' },
			{ value: 'recomm_prod4', align: 'center' },
			{ value: 'recomm_prod5', align: 'center' },
			{ value: 'recomm_prod6', align: 'center' },
			{ value: 'recomm_prod7', align: 'center' },
			{ value: 'recomm_prod8', align: 'center' },
		],
		logHeaders: [
			{
				row: [
					{ text: '이용 일시', width: '150px', sortable: false, align: 'center' },
					{ text: '업로드 이미지', width: '120px', sortable: false, align: 'center' },
					{ text: '검색 결과', width: '100px', sortable: false, align: 'center', colspan: 4 },
					{
						text: '추천 제품(/product/detail/0000)',
						width: '*',
						sortable: false,
						align: 'center',
						colspan: 8,
					},
				],
			},
		],
		monthly: 'true',
		period: null,
		desserts: {},
		loading: false,
	}),
	computed: {},
	watch: {
		monthly() {
			this.initPeriod()
		},
		period(val) {
			if (val?.length === 2) {
				const start = new Date(val[0])
				const end = new Date(val[1])
				let diffPeriod = 0

				if (this.monthly === 'true')
					diffPeriod = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1
				else diffPeriod = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1

				if ((this.monthly === 'true' && diffPeriod > 12) || (this.monthly === 'false' && diffPeriod > 31)) {
					this.initPeriod()
					this.$toastr.error('월별: 1년 이내, 일별: 한 달 이내', { timeOut: 1500 })
				}
			}
		},
	},
	mounted() {
		let date = this.common_get_period_from_now('month', 3)
		this.period = [date.startDate.replace(/^(\d{4})(\d{2})$/, '$1-$2'), date.endDate.replace(/^(\d{4})(\d{2})$/, '$1-$2')]
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_SEARCH_PICTURE']),
		async getList() {
			const date =
				this.monthly === 'true' ? this.common_get_period_from_now('month', 3) : this.common_get_period_from_now('day', 30)
			const filterDate = this.period ? this.common_inputDateFilter(this.period) : null
			const params = {
				monthly: this.monthly,
				startDate: filterDate ? filterDate.startDate : date.startDate,
				endDate: filterDate ? filterDate.endDate : date.endDate,
			}
			console.log('params', params)

			this.loading = true
			try {
				await this.ADMIN_ACT_STATISTICS_SEARCH_PICTURE(params).then(res => {
					console.log('res', res)
					this.desserts = res
				})
			} catch (e) {
				console.log('ADMIN_ACT_STATISTICS_SEARCH_PICTURE', e)
			}
			this.loading = false
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			const totalHeaders = [
				{ text: '날짜', value: 'get_date' },
				{ text: '검색 이용 수', value: 'search_count' },
			]
			const logProdHeaders = [
				{ text: '순위', value: 'ranking' },
				{ text: '제품명', value: 'name' },
				{ text: 'detail', value: 'recomm_prod' },
				{ text: '추천수', value: 'cnt' },
			]
			const logHeaders = [
				{ text: '이용 일시', value: 'regi_date' },
				{ text: '업로드 이미지', value: 'image' },
				{ text: '검색 결과1', value: 'picture_color1' },
				{ text: '검색 결과2', value: 'picture_color2' },
				{ text: '검색 결과3', value: 'picture_color3' },
				{ text: '검색 결과4', value: 'picture_color4' },
				{ text: '추천제품1', value: 'recomm_prod1' },
				{ text: '추천제품2', value: 'recomm_prod2' },
				{ text: '추천제품3', value: 'recomm_prod3' },
				{ text: '추천제품4', value: 'recomm_prod4' },
				{ text: '추천제품5', value: 'recomm_prod5' },
				{ text: '추천제품6', value: 'recomm_prod6' },
				{ text: '추천제품7', value: 'recomm_prod7' },
				{ text: '추천제품8', value: 'recomm_prod8' },
			]
			let excelHeaders = []
			if (this.tab === 0) excelHeaders = [...totalHeaders, ...logHeaders]
			else excelHeaders = [...logProdHeaders, ...logHeaders]

			const total = this.desserts.totalCount
			const logProd = this.desserts.searchLogProdList
			const log = this.desserts.searchLogList

			// total, prod, keyword 배열 하나로 합치기.
			// 단, 배열 길이가 서로 달라 가장 긴 배열 기준으로 빈 데이터를 채워 넣어 줌
			const maxLength = Math.max(total.length, logProd.length, log.length)
			const padArray = (arr, length) => arr.concat(Array.from({ length: length - arr.length }, () => ({})))

			const paddedArray1 = this.tab === 0 ? padArray(total, maxLength) : padArray(logProd, maxLength)
			const paddedArray2 = padArray(log, maxLength)

			let result = paddedArray1.map((_, index) => ({
				...paddedArray1[index],
				...paddedArray2[index],
			}))

			// 엑셀 컬럼 순서 정렬하기
			result = result.map(item => {
				return {
					get_date: item.get_date,
					search_count: item.search_count,
					ranking: item.ranking,
					name: item.name,
					recomm_prod: item.recomm_prod,
					cnt: item.cnt,
					regi_date: item.regi_date,
					image: item.image,
					picture_color1: item.picture_color1,
					picture_color2: item.picture_color2,
					picture_color3: item.picture_color3,
					picture_color4: item.picture_color4,
					object_color: item.object_color,
					recomm_prod1: item.recomm_prod1,
					recomm_prod2: item.recomm_prod2,
					recomm_prod3: item.recomm_prod3,
					recomm_prod4: item.recomm_prod4,
					recomm_prod5: item.recomm_prod5,
					recomm_prod6: item.recomm_prod6,
					recomm_prod7: item.recomm_prod7,
					recomm_prod8: item.recomm_prod8,
				}
			})
			console.log('result', result, excelHeaders)
			this.common_create_excel(this.common_excel_data_convert(result, excelHeaders), '사진 검색 이용률')
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.table-search) {
	display: none;
}
</style>
