<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>
						<v-col
							:key="monthly"
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								:type="monthly === 'true' ? 'month' : 'date'"
								title="기간"
								date-picker
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-col class="pl-0">
				<h2>관리자 메뉴 사용 수</h2>
			</v-col>
			<CommonDataTableGridType04
				v-model="model"
				:binding-headers="adminBindingHeaders"
				:headers="adminHeaders"
				:line-headers="true"
				:desserts="adminData"
				:height="common_tableHeight(450)"
				:loading="loading"
			/>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<v-col class="pl-0">
				<h2>사용자 메뉴 이용 수</h2>
			</v-col>
			<CommonDataTableGridType04
				v-model="model"
				:binding-headers="userBindingHeaders"
				:headers="userHeaders"
				:line-headers="true"
				:desserts="userData"
				:height="common_tableHeight(450)"
				:loading="loading"
			/>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsServiceUseMenu',
	mixins: [common, admin],
	data: () => ({
		model: [],
		monthly: 'true',
		period: null,
		params: {},
		loading: false,
		adminData: [],
		userData: [],
		adminBindingHeaders: [
			{ value: 'get_date', align: 'center' },
			{ value: 'housewarming_count', align: 'center' },
			{ value: 'exphousewarming_count', align: 'center' },
			{ value: 'special_count', align: 'center' },
			{ value: 'catalogue_count', align: 'center' },
			{ value: 'showroom_count', align: 'center' },
			{ value: 'knowhow_count', align: 'center' },
			{ value: 'video_count', align: 'center' },
			{ value: 'instamedia_count', align: 'center' },
			{ value: 'event_count', align: 'center' },
			{ value: 'notice_count', align: 'center' },
		],
		userBindingHeaders: [
			{ value: 'get_date', align: 'center' },
			{ value: 'picturesearch_count', align: 'center' },
			{ value: 'colorsearch_count', align: 'center' },
			{ value: 'fastestimate_count', align: 'center' },
			{ value: 'estimate_count', align: 'center' },
			{ value: 'estconsult_count', align: 'center' },
			{ value: 'guarantee_count', align: 'center' },
			{ value: 'asservice_count', align: 'center' },
			{ value: 'askservice_count', align: 'center' },
		],
		adminHeaders: [
			{
				row: [
					{ text: '구분', rowspan: 2, width: '120px', align: 'center' },
					{ text: '집들이', colspan: 2, width: '*', align: 'center' },
					{ text: '제품찾기', colspan: 2, width: '*', align: 'center' },
					{ text: '커뮤니티', colspan: 4, width: '*', align: 'center' },
					{ text: '동화쇼룸', rowspan: 2, width: '*', align: 'center' },
					{ text: '동화소식', rowspan: 2, width: '*', align: 'center' },
				],
			},
			{
				row: [
					{ text: '3D집들이', width: '*', align: 'center' },
					{ text: '전문가집들이', width: '*', align: 'center' },
					{ text: '특판문의', width: '*', align: 'center' },
					{ text: '카탈로그', width: '*', align: 'center' },
					{ text: '노하우', width: '*', align: 'center' },
					{ text: '마루잘알TV', width: '*', align: 'center' },
					{ text: '마루스타그램', width: '*', align: 'center' },
					{ text: '이벤트', width: '*', align: 'center' },
				],
			},
		],
		userHeaders: [
			{
				row: [
					{ text: '구분', rowspan: 2, width: '120px', align: 'center' },
					{ text: '마루 추천', colspan: 2, width: '*', align: 'center' },
					{ text: '인테리어', colspan: 3, width: '*', align: 'center' },
					{ text: '고객센터', colspan: 3, width: '*', align: 'center' },
				],
			},
			{
				row: [
					{ text: '사진 검색', width: '*', align: 'center' },
					{ text: '색상 검색', width: '*', align: 'center' },
					{ text: '간편견적', width: '*', align: 'center' },
					{ text: '견적내기', width: '*', align: 'center' },
					{ text: '맞춤 견적 신청', width: '*', align: 'center' },
					{ text: '품질보증', width: '*', align: 'center' },
					{ text: 'A/S 신청', width: '*', align: 'center' },
					{ text: '1:1문의', width: '*', align: 'center' },
				],
			},
		],
		adminExcelHeaders: [
			{ text: '구분', value: 'get_date' },
			{ text: '집들이 3D집들이', value: 'housewarming_count' },
			{ text: '집들이 전문가집들이', value: 'exphousewarming_count' },
			{ text: '제품찾기 특판문의', value: 'special_count' },
			{ text: '제품찾기 카탈로그', value: 'catalogue_count' },
			{ text: '커뮤니티 노하우', value: 'knowhow_count' },
			{ text: '커뮤니티 마루잘알TV', value: 'video_count' },
			{ text: '커뮤니티 마루스타그램', value: 'instamedia_count' },
			{ text: '커뮤니티 이벤트', value: 'event_count' },
			{ text: '동화쇼룸 ', value: 'showroom_count' },
			{ text: '동화소식 ', value: 'notice_count' },
		],
		userExcelHeaders: [
			{ text: '구분', value: 'get_date' },
			{ text: '마루추천 사진검색', value: 'picturesearch_count' },
			{ text: '마루추천 색상검색', value: 'colorsearch_count' },
			{ text: '인테리어 간편견적', value: 'fastestimate_count' },
			{ text: '인테리어 견적내기', value: 'estimate_count' },
			{ text: '인테리어 맞춤견적신청', value: 'estconsult_count' },
			{ text: '고객센터 품질보증', value: 'guarantee_count' },
			{ text: '고객센터 A/S 신청', value: 'asservice_count' },
			{ text: '고객센터 1:1문의', value: 'askservice_count' },
		],
	}),
	computed: {},
	watch: {
		monthly() {
			this.initPeriod()
		},
	},
	created() {
		const year = this.common_get_this_year()
		this.period = [year.startDate, year.endDate]
		this.params = { startDate: year.startDate, endDate: year.endDate }
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_MENU']),
		async getList() {
			this.loading = true
			this.params.monthly = this.monthly
			console.log('this.params', this.params)
			await this.ADMIN_ACT_STATISTICS_MENU(this.params).then(res => {
				console.log('res', res)
				this.adminData = res.admin
				this.userData = res.user
				this.loading = false
			})
		},
		searchItems() {
			let filterDate = {}
			if (this.period) filterDate = this.common_inputDateFilter(this.period)
			else {
				filterDate = this.monthly === 'true' ? this.common_get_this_year() : this.common_get_this_month()
			}

			this.period = [filterDate.startDate, filterDate.endDate]
			this.params.startDate = filterDate.startDate
			this.params.endDate = filterDate.endDate
			console.log('search', this.params, this.period)

			this.getList()
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			this.common_create_excel(
				this.common_excel_data_convert(this.adminData, this.adminExcelHeaders),
				'관리자 메뉴 사용 수 통계',
			)
			this.common_create_excel(
				this.common_excel_data_convert(this.userData, this.userExcelHeaders),
				'사용자 메뉴 이용 수 통계',
			)
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.table-search) {
	display: none;
}
:deep(.v-data-table) {
	tr:first-child:not(.v-data-table__empty-wrapper) {
		background: #f8e5d8;
	}
	tr:nth-child(2):not(.v-data-table__empty-wrapper) {
		background: #aecbb6;
	}
}
</style>
