<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="monthly"
								title="통계유형"
								:radio-group="admin_statistics_period"
							/>
						</v-col>
						<v-col
							:key="monthly"
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								ref="period"
								v-model="period"
								:type="monthly === 'true' ? 'month' : 'date'"
								title="기간"
								date-picker
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<!-- <v-row justify="center">
			<v-col
				cols="12"
				md="10"
				align="middle"
				class="mt-8 pt-0"
			>
				<CommonChartsMultiLineChart
					:chart-data="filterChartData"
					height="305"
				/>
			</v-col>
		</v-row> -->
		<v-col class="pa-4 px-md-4 pt-0">
			<v-col class="pl-0">
				<h2>집들이 콘텐츠 이용률</h2>
			</v-col>
			<CommonDataTableGridType04
				v-model="model"
				:binding-headers="bindingHeaders"
				:headers="headers"
				:line-headers="true"
				:desserts="desserts"
				:height="common_tableHeight(450)"
				:loading="loading"
			/>
		</v-col>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminStatisticsServiceUseHouseSpecial',
	mixins: [common, admin],
	data: () => ({
		model: [],
		monthly: 'true',
		period: null,
		params: {},
		loading: false,
		desserts: [],
		bindingHeaders: [
			{ value: 'get_date', align: 'center' },
			{ value: 'housewarming_pageViews', align: 'center' },
			{ value: 'housewarmingdetail_pageViews', align: 'center' },
			{ value: 'housewarming3d_pageViews', align: 'center' },
			{ value: 'exphousewarming_pageViews', align: 'center' },
			{ value: 'exphousewarmingdetail_pageViews', align: 'center' },
		],
		headers: [
			{
				row: [
					{ text: '구분', rowspan: 2, width: '120px', align: 'center' },
					{ text: '3D 집들이', colspan: 3, width: '*', align: 'center' },
					{ text: '전문가 집들이', colspan: 3, width: '*', align: 'center' },
				],
			},
			{
				row: [
					{ text: '리스트 PV', width: '*', align: 'center' },
					{ text: '상세 PV', width: '*', align: 'center' },
					{ text: '3D 집들이 실행수', width: '*', align: 'center' },
					{ text: '리스트 PV', width: '*', align: 'center' },
					{ text: '상세 PV', width: '*', align: 'center' },
				],
			},
		],
		excelHeaders: [
			{ text: '구분', value: 'get_date' },
			{ text: '3D 집들이 리스트 PV', value: 'housewarming_pageViews' },
			{ text: '3D 집들이 상세 PV', value: 'housewarmingdetail_pageViews' },
			{ text: '3D 집들이 3D PV', value: 'housewarming3d_pageViews' },
			{ text: '전문가 집들이 리스트 PV', value: 'exphousewarming_pageViews' },
			{ text: '전문가 집들이상세 PV', value: 'exphousewarmingdetail_pageViews' },
		],
	}),
	computed: {
		filterChartData() {
			if (!this.desserts) return []
			let result = [
				{ name: '3D 집들이 리스트 PV', data: [] },
				{ name: '3D 집들이 상세 PV', data: [] },
				{ name: '3D 집들이 3D PV', data: [] },
				{ name: '전문가 집들이 리스트 PV', data: [] },
				{ name: '전문가 집들이상세 PV', data: [] },
			]
			let keys = [
				'housewarming_pageViews',
				'housewarmingdetail_pageViews',
				'housewarming3d_pageViews',
				'exphousewarming_pageViews',
				'exphousewarmingdetail_pageViews',
			]
			let chartData = this.desserts.filter(x => x.get_date !== '총계').sort((a, b) => (a.get_date > b.get_date ? 1 : -1))
			result.forEach((x, i) => {
				chartData.forEach(y => {
					x.data.push({ label: y.get_date, value: y[keys[i]] })
				})
			})

			console.log('result', result)
			return result
		},
	},
	watch: {
		monthly() {
			this.initPeriod()
		},
	},
	created() {
		const year = this.common_get_this_year()
		this.period = [year.startDate, year.endDate]
		this.params = { startDate: year.startDate, endDate: year.endDate }
		this.getList()
	},
	destroyed() {},
	methods: {
		...mapActions(['ADMIN_ACT_STATISTICS_HOUSEWARMING']),
		async getList() {
			this.loading = true
			this.params.monthly = this.monthly
			console.log('this.params', this.params)
			await this.ADMIN_ACT_STATISTICS_HOUSEWARMING(this.params).then(res => {
				this.desserts = res
				this.loading = false
			})
			console.log('this.desserts', this.desserts)
		},
		searchItems() {
			let filterDate = {}
			if (this.period) filterDate = this.common_inputDateFilter(this.period)
			else {
				filterDate = this.monthly === 'true' ? this.common_get_this_year() : this.common_get_this_month()
			}

			this.period = [filterDate.startDate, filterDate.endDate]
			this.params.startDate = filterDate.startDate
			this.params.endDate = filterDate.endDate
			console.log('search', this.params, this.period)

			this.getList()
		},
		initPeriod() {
			this.period = null
			this.$refs.period.$refs.datePicker.cancel()
		},
		makeExcelFile() {
			this.common_create_excel(this.common_excel_data_convert(this.desserts, this.excelHeaders), '집들이 콘텐츠 이용률')
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.table-search) {
	display: none;
}
:deep(.v-data-table) {
	tr:first-child:not(.v-data-table__empty-wrapper) {
		background: #e4efdc;
	}
}
</style>
